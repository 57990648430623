import axios from "@/http";

// 获取商品一/二级分类
function getGoodsCategory(data) {
    return axios({
        method: "post",
        url: '/index/api',
        data,
    });
}

// 获取商品详情
function getGoodsDetail(data) {
    return axios({
        method: "post",
        url: '/index/api',
        data,
    });
}

// 兑换商品
function exchangeGoods(data) {
    return axios({
        method: "post",
        url: '/index/api',
        data,
    });
}

// 获取用户信息
function getUser(data) {
    return axios({
        method: "post",
        url: '/index/api',
        data,
    });
}

// 上传图片
function uploadImg(data) {
    return axios({
        method: "post",
        url: '/SysFile/Upload',
        data,
    });
}

export { getGoodsCategory, getGoodsDetail, exchangeGoods, getUser, uploadImg };
