import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import store from "./store";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css"; // 重置不同浏览器之间的标签默认样式
// 解决Dialog和Drawer组件鼠标划出弹出框关闭问题
import popupAutoClose from '@/components/popupAutoClose.js';
// 引用lib-flexible插件
import "lib-flexible"

// 关闭生产模式下给出的提示
Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(popupAutoClose);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
